import React, { FC } from "react"
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField } from "react-admin-json-view"

import { EmailCampaignStatus } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const emailCampaignStatusChoices = [
  ...Object.keys(EmailCampaignStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: any) => {
  return (
    <SimpleForm {...props}>
      <TextInput helperText="Internal labeling" required source="name" />

      <ReferenceInput reference="Community" source="communityId">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <BooleanInput source="immediateSend" />
      <DateTimeInput source="sendAt" />

      <SelectInput
        choices={emailCampaignStatusChoices}
        defaultValue={EmailCampaignStatus.Draft}
        required
        source="status"
      />

      <TextInput required source="subject" />
      <TextInput required source="senderEmail" />
      <TextInput multiline required source="htmlDesign" style={{ width: 900 }} />

      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {props.children}
    </SimpleForm>
  )
}

export const EmailCampaignCreate: FC = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const EmailCampaignEdit: FC = (props) => (
  <Edit {...props}>
    <CreateEditForm toolbar={<EditToolbar />}>
      <h3>Analytics</h3>
      <TextField label="Target audience" source="totalUsers" />
      <TextField label="Delivered" source="delivered" />
      <TextField label="Opens" source="opens" />
      <TextField label="Clicks" source="clicks" />
      <TextField label="Unsubscribes" source="unsubscribes" />
      <h3>Postmark result</h3>
      <JsonField label="Postmark result" source="postmarkResult" />
    </CreateEditForm>
  </Edit>
)

export const EmailCampaignList: FC = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Community" source="community.name" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField reference="Community" source="communityId">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="subject" />
      <TextField source="senderEmail" />
      <DateField showTime source="sendAt" />
      <BooleanField source="immediateSend" />
      <ChipField source="status" />
    </Datagrid>
  </List>
)
