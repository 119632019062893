/* eslint-disable sort-keys */
import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField, JsonInput } from "react-admin-json-view"
import semver from "semver"

import MediaWithUpload from "./components/MediaWithUpload"
import { Module } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const currencyChoices = [
  { id: "SEK", name: "SEK" },
  { id: "DKK", name: "DKK" },
  { id: "EUR", name: "EUR" },
]

const countryChoices = [
  { id: "SE", name: "Sweden" },
  { id: "DK", name: "Denmark" },
  { id: "NL", name: "Netherlands" },
  { id: "DE", name: "Germany" },
  { id: "IT", name: "Italy" },
]

const paymentChoices = [
  { id: "StripeCard", name: "Stripe" },
  { id: "StripeSwish", name: "Swish" },
  { id: "StripeIdeal", name: "iDEAL" },
  { id: "StripeMobilePay", name: "MobilePay" },
]

const availableModules = [
  ...Object.values(Module).map((k) => ({
    id: k,
    name: k,
  })),
]

export const CommunityCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <TextInput source="landingCode" />
      <NumberInput source="numPersonalizedInviteCodes" />
      <SelectInput choices={currencyChoices} source="currency" />
      <SelectInput choices={countryChoices} source="country" />
      <BooleanInput source="requireVerifiedEmailForShopAccess" />
      <TextInput source="code" />
      <TextInput source="globalInviteCode" />
      <TextInput source="globalTicketsLink" />
      <ReferenceArrayInput reference="User" source="admins">
        <AutocompleteArrayInput optionText="username" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="User" source="members">
        <AutocompleteArrayInput optionText="username" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const CommunityEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="General">
          <TextField source="id" />
          <TextField source="code" />
          <TextInput source="name" />
          <TextInput source="location" />
          <MarkdownInput source="description" />
          <JsonInput
            jsonString={false}
            reactJsonOptions={{
              collapsed: true,
              displayDataTypes: false,
              enableClipboard: true,
            }}
            source="links"
          />
          <NumberInput source="order" />
          <SelectInput choices={countryChoices} source="country" />
          <ReferenceArrayInput reference="Location" source="visibleInLocations">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <BooleanInput source="requestToJoin" />
          <br />
          <TextInput source="landingCode" />
          <TextField source="landingLink" />
          <TextInput source="emailPostmarkStream" />
          <TextInput source="globalInviteCode" />
          <br />
          <TextInput
            helperText="Format: version or version+codepushNUMBER (example: 3.7.0 or 3.7.0)"
            source="minAppVersion"
            validate={(val) => {
              if (val && !semver.valid(val)) {
                return "Invalid version"
              }
              return undefined
            }}
          />
          <SelectInput choices={currencyChoices} source="currency" />
          <SelectArrayInput
            choices={paymentChoices}
            optionValue="id"
            source="availablePaymentMethods"
          />
          <TextInput source="stripeConnectedAccountId" />
          <JsonInput
            jsonString={false}
            reactJsonOptions={{
              collapsed: true,
              displayDataTypes: false,
              enableClipboard: true,
              name: null,
            }}
            source="paymentFees"
          />
          <MediaWithUpload
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            filename={props["id"] as string}
            label="Community Icon (app home)"
            path="community/icon"
            source={`iconUrl`}
          />
          <NumberInput source="numPersonalizedInviteCodes" />
          <TextField label="Total members" source="members.length" />
          <ReferenceArrayInput reference="User" source="admins">
            <AutocompleteArrayInput optionText="username" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Design">
          <BooleanInput source="enableModulesV2" />
          <SelectArrayInput choices={availableModules} optionValue="id" source="availableModules" />
          <BooleanInput source="enableStylePolling" />
          <JsonInput
            jsonString={false}
            reactJsonOptions={{
              collapsed: true,
              displayDataTypes: false,
              enableClipboard: true,
              name: null,
            }}
            source="styleOverride"
          />
          {/* <JsonEditor source="styleOverride" /> */}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const CommunityList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceArrayField label="Admins" reference="User" source="admins">
        <SingleFieldList>
          <ChipField source="username" />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField label="Member count" source="members.length" />
      <NumberField source="numPersonalizedInviteCodes" />
      <TextField source="globalInviteCode" />
    </Datagrid>
  </List>
)

export const CommunityShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <SelectField choices={countryChoices} source="country" />
      <TextInput source="minAppVersion" />
      <NumberField source="numPersonalizedInviteCodes" />
      <TextField source="globalInviteCode" />
      <TextField source="globalTicketsLink" />
      <JsonField
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleShowLayout>
  </Show>
)
